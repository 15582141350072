import React from 'react';

import logo from '../../../assets/logo.png';
import menu from '../../../images/menu.png';

import { CollectionButton, HeaderContainer, HeaderLogo, HeaderMenu, HeaderMenuButton } from './styles';

import {COLLECTIONS} from '../../../utils/constants';

const Header = () => {
  return (
    <HeaderContainer>
      <HeaderLogo src={logo} alt='Verum'/>
      <HeaderMenu>
        <HeaderMenuButton src={menu} alt='Menu'/>
        {COLLECTIONS.map((collection) => {
          return <CollectionButton>
            {collection}
          </CollectionButton>
        })}
      </HeaderMenu>
    </HeaderContainer>

  );
};
export default Header;
