import styled from "styled-components";

import { Container } from "../../common/styles";
import { breakpoints } from "../../../utils/theme";

export const ProductViewContainer = styled(Container)`
  padding-top: 10px;
  @media (min-width: ${breakpoints.tablet}px) {
    flex-direction: row;
  }
`;

export const ProductViewImage = styled.img`
  padding-top: 10%;
  width: 100%;
  height: 300px;
  object-fit: cover;
  @media (min-width: ${breakpoints.tablet}px) {
    max-width: 60%;
    height: inherit;
  }
`;

export const ProductViewTexts = styled(ProductViewContainer)`
  margin: 10px;
  @media (min-width: ${breakpoints.tablet}px) {
    flex-direction: column;
    align-self: center;
  }
`;
