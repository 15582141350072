import React from 'react';
import {Switch, Route} from 'react-router-dom';

import AppContent from './modules/app';
import { HOME_ROUTE} from './utils/routes';

function App() {
  return (
    <Switch>
      {/* <Route exact path={LOGIN_ROUTE} component={Login} /> */}
      <Route exact path={HOME_ROUTE} component={AppContent} />
    </Switch>
  );
}

export default App;
