import React from "react";

import {
  ProductViewContainer,
  ProductViewImage,
  ProductViewTexts,
} from "./styles";
import vertical from "../../../images/vertical.jpg";
import { Title, Typography, TypographyLink } from "../../common/styles";

const product = {
  name: "Buzo Tizón",
  price: "$4890",
  image: vertical,
};

const ProductView = () => {
  return (
    <ProductViewContainer>
      <ProductViewImage src={product.image} />
      <ProductViewTexts>
        <Title>{product.name}</Title>
        <Typography>{product.price}</Typography>
        <TypographyLink>Ver detalle</TypographyLink>
      </ProductViewTexts>
    </ProductViewContainer>
  );
};
export default ProductView;
