import React from "react";

import { Container } from "../../common/styles";
import slider1 from "../../../images/slider1.jpg";
import { SliderImage } from "./styles";
import ProductView from "../../components/productView";
import ColecctionView from "../../components/colectionView";

const Home = () => {
  const renderSlider = () => {
    return <SliderImage src={slider1}></SliderImage>;
  };
  
  return (
    <Container>
      {renderSlider()}
      <ProductView />
      <ColecctionView/>
      {/* <NewsLetter/> */}
    </Container>
  );
};

export default Home;
