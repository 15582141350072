import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Title = styled.text`
  font-size: 35px;
  font-weight: bold;
`;

export const Typography = styled.text`
  font-size: 18px;
  margin-bottom: 10px;
`;

export const TypographyBold = styled(Typography)`
  font-weight: bold;
`;

export const TypographyLink = styled.text`
  font-size: 14px;
  text-decoration: underline;
`;

export const TypographySmall = styled(Typography)`
  font-size: 14px;
`;

export const Input = styled.input`
  border: none;
  background-color:#f6f6f6;
  border-radius: 5px;
  outline: none;
  width: 200px;
  padding: 10px;
  height: 25px;
`;

export const InputLabel = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
`;

export const Button = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const ActionButton = styled(Button)`
  background-color: black;
  border-radius: 5px;
  color: white;
  width: 150px;
  padding: 10px;
  height: 45px;
`;

