import React from 'react';

import { FooterContainer } from './styles';

const Footer = () => {
  return (
    <FooterContainer>
        Verum
    </FooterContainer>
  );
};
export default Footer;
