import styled from "styled-components";

import { Container } from "../../common/styles";
import { breakpoints } from "../../../utils/theme";

export const CollectionImagesText = styled(Container)`
  padding: 10px;
  padding-top:10%;
  text-align: initial;
  @media (min-width: ${breakpoints.tablet}px) {
    align-items: center;
    text-align: center;
    padding-top:5%;
  }
`;

export const CollectionImages = styled(Container)`
  padding-top: 10px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
`;

export const CollectionImage = styled.img`
  padding-top: 10px;
  width: 25%;
`;
