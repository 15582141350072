import React from "react";
import { Switch, Route } from "react-router-dom";
import Footer from "../components/footer";

import Header from "../components/header";
import Home from "../screens/home";
import { Page } from "./styles";

const AppContent = () => {
  return (
    <Page>
      <Header />
      <Switch>
        <Route exact path={"/*"} component={Home} />
        {/* <Route exact path={GROUND_ROUTE} component={Ground} /> */}
      </Switch>
      <Footer />
    </Page>
  );
};

export default AppContent;
