export const palette = {
  white: 'white',
  black: 'black'
};

export const typography = {
  semiBold: 600,
  bold: 700,
};

export const breakpoints = {
  mobile: 400,
  phablet: 550,
  tablet: 750,
  largeTablet: 850,
  desktop: 1000,
  desktopLarge: 1200,
  hd: 1400,
  ultraHd: 1900,
};
